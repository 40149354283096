import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import Seo from '../components/seo';
import 'bootstrap';

export default ({children, data, location}) => {
  return (
    <div className='container-fluid m-0 p-0'>
      <Seo data={data} location={location} />
      <Header />
      <div className='container col-xs-12'>
        <div className='row mt-5 mb-5'>
          <div className="container">
            <main>{children}</main>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}